import { createContext, useEffect, useState } from "react";
import axios from "axios";

// Settings
import settings from "../settings.json";

// Utils
import { isAtLeastClientAdmin, isAtLeastSiteAdmin, isSuperAdmin } from "../utils/permission";
import { genDoc } from "../utils/firebase";

// Hooks
import { useFetchUser } from "../hooks/useFetchUser";

// Firebase
import { collection, doc, getDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../firebase/firebase";

// Interfaces
import { Site, SiteVersion } from "../interfaces/Site";
import { ChargingStation } from "../interfaces/ChargingStation";
import { Usage } from "../interfaces/Usage";
import { User } from "../interfaces/User";
import { Client } from "../interfaces/Client";
import { HealthCheck } from "../interfaces/HealthCheck";
import { HighestChargingStationsReduction } from "../interfaces/HighestChargingStationsReduction";
import { HighestChargingStationsPower } from "../interfaces/HighestChargingStationsPower";
import { SitesUsage } from "../interfaces/SitesUsage";
import { ChargingStationsUsage } from "../interfaces/ChargingStationsUsage";
import { HighestBuildingPowerUsage } from "../interfaces/HighestBuildingPowerUsage";
import { Alert } from "../interfaces/Alert";

interface MainContextInterface {
    sites: Site[];
    // eslint-disable-next-line
    setSites: (sites: Site[]) => void;
    fetchSites: () => void;
    // eslint-disable-next-line
    getSiteVersionById: (siteId: string) => Promise<SiteVersion | undefined>;
    chargingStations: ChargingStation[];
    // eslint-disable-next-line
    setChargingStations: (chargingStation: ChargingStation[]) => void;
    usages: Usage[];
    // eslint-disable-next-line
    setUsages: (usages: Usage[]) => void;
    fetchUsages: () => Promise<void>;
    users: User[];
    // eslint-disable-next-line
    setUsers: (users: User[]) => void;
    clients: Client[];
    // eslint-disable-next-line
    setClients: (clients: Client[]) => void;
    healthChecks: HealthCheck[];
    // eslint-disable-next-line
    setHealthChecks: (healthChecks: HealthCheck[]) => void;
    alerts: Alert[];
    // eslint-disable-next-line
    setAlerts: (alerts: Alert[]) => void;
    fetchAlerts: () => Promise<void>;
    // eslint-disable-next-line
    getHighestChargingStationReductionById: (chargingStationId: string) => Promise<HighestChargingStationsReduction | undefined>;
    // eslint-disable-next-line
    getHighestChargingStationPowerById: (chargingStationId: string) => Promise<HighestChargingStationsPower | undefined>;
    // eslint-disable-next-line
    getHighestBuildingPowerUsagesBySiteId: (siteId: string) => Promise<HighestBuildingPowerUsage | undefined>;
    // eslint-disable-next-line
    getSiteUsageById: (siteId: string) => Promise<SitesUsage>;
    // eslint-disable-next-line
    getChargingStationUsageById: (chargingStationId: string) => Promise<ChargingStationsUsage>;
    // eslint-disable-next-line
    fetchChargingStations: () => Promise<void>;
}

export const MainContext = createContext<MainContextInterface>({
    sites: [],
    // eslint-disable-next-line
    setSites: (sites: Site[]) => {},
    fetchSites: () => Promise.resolve(),
    // eslint-disable-next-line
    getSiteVersionById: (siteId: string) => Promise.resolve({} as SiteVersion | undefined),
    chargingStations: [],
    // eslint-disable-next-line
    setChargingStations: (chargingStations: ChargingStation[]) => {},
    usages: [],
    // eslint-disable-next-line
    setUsages: (usages: Usage[]) => {},
    // eslint-disable-next-line
    fetchUsages: () => Promise.resolve(),
    users: [],
    // eslint-disable-next-line
    setUsers: (users: User[]) => {},
    clients: [],
    // eslint-disable-next-line
    setClients: (clients: Client[]) => {},
    healthChecks: [],
    // eslint-disable-next-line
    setHealthChecks: (healthChecks: HealthCheck[]) => {},
    alerts: [],
    // eslint-disable-next-line
    setAlerts: (alerts: Alert[]) => {},
    fetchAlerts: () => Promise.resolve(),
    // eslint-disable-next-line
    getHighestChargingStationReductionById: (chargingStationId: string) => Promise.resolve({} as HighestChargingStationsReduction | undefined),
    // eslint-disable-next-line
    getHighestChargingStationPowerById: (chargingStationId: string) => Promise.resolve({} as HighestChargingStationsPower | undefined),
    // eslint-disable-next-line
    getHighestBuildingPowerUsagesBySiteId: (siteId: string) => Promise.resolve({} as HighestBuildingPowerUsage | undefined),
    // eslint-disable-next-line
    getSiteUsageById: (siteId: string) => Promise.resolve({} as SitesUsage),
    // eslint-disable-next-line
    getChargingStationUsageById: (chargingStationId: string) => Promise.resolve({} as ChargingStationsUsage),
    // eslint-disable-next-line
    fetchChargingStations: () => Promise.resolve(),
});

const MainContextProvider: React.FC<any> = ({ children }) => {
    const { currentUser, authUser } = useFetchUser();

    // MANU: should sites, chargingStations, clients and users be onSnapshots ?

    // States
    const [sites, setSites] = useState<Site[]>([]);
    const [chargingStations, setChargingStations] = useState<ChargingStation[]>([]);
    const [usages, setUsages] = useState<Usage[]>([]);
    const [users, setUsers] = useState<User[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const [healthChecks, setHealthChecks] = useState<HealthCheck[]>([]);
    const [alerts, setAlerts] = useState<Alert[]>([]);

    const fetchSites = async () => {
        try {
            if (isAtLeastSiteAdmin(currentUser)) {
                const payload = await getDocs(query(collection(firestore, "Sites"), where("isDeleted", "==", false)));
                setSites(payload.docs.map(genDoc<Site>()));
            }
        } catch (e) {
            console.error(e);
        }
    };

    const fetchChargingStations = async () => {
        try {
            const payload = await getDocs(query(collection(firestore, "ChargingStations"), where("isDeleted", "==", false)));
            setChargingStations(payload.docs.map(genDoc<ChargingStation>()));
        } catch (e) {
            console.error(e);
        }
    };

    const fetchUsages = async () => {
        if (isAtLeastSiteAdmin(currentUser)) {
            const payload = await getDocs(query(collection(firestore, "Usages"), where("isDeleted", "==", false)));
            setUsages(payload.docs.map(genDoc<Usage>()));
        }
    };

    const fetchUsers = async () => {
        if (isSuperAdmin(currentUser)) {
            const payload = await getDocs(query(collection(firestore, "Users"), where("isDeleted", "==", false)));
            setUsers(payload.docs.map(genDoc<User>()));
        }
    };

    const fetchClients = async () => {
        if (isAtLeastClientAdmin(currentUser)) {
            const payload = await getDocs(query(collection(firestore, "Clients"), where("isDeleted", "==", false)));
            setClients(payload.docs.map(genDoc<Client>()));
        }
    };

    const fetchHealthChecks = async () => {
        try {
            if (authUser) {
                // Get the Firebase Auth token
                const token = await authUser.getIdToken();

                const promises = sites.map(async site => {
                    const payload = await axios.get(
                        `${process.env.NODE_ENV === "production" ? settings.app.backendProdUrl : settings.app.backendDevUrl}/healthchecks/${site.id}`,
                        {
                            headers: {
                                Authorization: `${token}`,
                            },
                        }
                    );

                    if (payload?.data?.success) {
                        return { siteId: site.id, online: payload.data.success };
                    } else {
                        return { siteId: site.id, online: false };
                    }
                });

                let localHealthChecks = await Promise.all(promises);
                localHealthChecks = localHealthChecks.filter(x => x);
                setHealthChecks(localHealthChecks as any);
            }
        } catch (e: any) {
            if (e.response) {
                console.error(e.response);
            }
            setHealthChecks([]);
        }
    };

    const fetchAlerts = async () => {
        try {
            // Get the Firebase Auth token
            const token = await authUser?.getIdToken();

            const payload = await axios.get(
                `${process.env.NODE_ENV === "production" ? settings.app.backendProdUrl : settings.app.backendDevUrl}/alerts`,
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            );

            if (payload?.data?.success) {
                setAlerts(payload.data.success);
            }
        } catch (e) {
            console.error(e);
        }
    };

    const getSiteVersionById = async (siteId: string) => {
        try {
            const payload = await getDoc(doc(firestore, "Sites", siteId, "Settings", "Version"));
            return payload.data() as SiteVersion;
        } catch (e) {
            console.error(e);
        }
    };

    const getHighestChargingStationReductionById = async (chargingStationId: string) => {
        if (isSuperAdmin(currentUser)) {
            const payload = await getDocs(
                query(
                    collection(firestore, "HighestChargingStationsReduction"),
                    where("isDeleted", "==", false),
                    where("chargingStationId", "==", chargingStationId),
                    orderBy("max", "desc"),
                    limit(1)
                )
            );

            const reductions = payload.docs.map(genDoc<HighestChargingStationsReduction>());

            if (reductions?.length) {
                return reductions[0];
            }
        }
    };

    const getHighestChargingStationPowerById = async (chargingStationId: string) => {
        if (isSuperAdmin(currentUser)) {
            const payload = await getDocs(
                query(
                    collection(firestore, "HighestChargingStationsPower"),
                    where("isDeleted", "==", false),
                    where("chargingStationId", "==", chargingStationId),
                    orderBy("max", "desc"),
                    limit(1)
                )
            );

            const powers = payload.docs.map(genDoc<HighestChargingStationsPower>());

            if (powers?.length) {
                return powers[0];
            }
        }
    };

    const getHighestBuildingPowerUsagesBySiteId = async (siteId: string) => {
        const start = new Date(new Date().getFullYear(), new Date().getMonth(), 1, 0, 0, 0, 0);
        const end = new Date(new Date().getFullYear(), new Date().getMonth(), 31, 23, 23, 59, 999);

        if (isSuperAdmin(currentUser)) {
            const payload = await getDocs(
                query(
                    collection(firestore, "HighestBuildingPowerUsages"),
                    where("siteId", "==", siteId),
                    where("createdAt", ">=", start),
                    where("createdAt", "<=", end),
                    orderBy("max", "desc"),
                    orderBy("createdAt", "desc"),
                    limit(1)
                )
            );

            const usages = payload.docs.map(genDoc<HighestBuildingPowerUsage>());

            if (usages?.length) {
                return usages[0];
            }
        }
    };

    const getSiteUsageById = async (siteId: string) => {
        try {
            // Get the Firebase Auth token
            const token = await authUser?.getIdToken();

            const payload = await axios.get(
                `${process.env.NODE_ENV === "production" ? settings.app.backendProdUrl : settings.app.backendDevUrl}/sites/usages/${siteId}`,
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            );

            if (payload?.data?.success) {
                return payload.data.success;
            }
        } catch (e) {
            console.error(e);
            return undefined;
        }
    };

    const getChargingStationUsageById = async (chargingStationId: string) => {
        try {
            // Get the Firebase Auth token
            const token = await authUser?.getIdToken();

            const payload = await axios.get(
                `${
                    process.env.NODE_ENV === "production" ? settings.app.backendProdUrl : settings.app.backendDevUrl
                }/chargingStations/usages/${chargingStationId}`,
                {
                    headers: {
                        Authorization: `${token}`,
                    },
                }
            );

            if (payload?.data?.success) {
                return payload.data.success;
            }
        } catch (e) {
            console.error(e);
            return undefined;
        }
    };

    // fetch collections
    useEffect(() => {
        fetchSites();
        fetchChargingStations();
        fetchUsers();
        fetchClients();
        fetchHealthChecks();
        fetchAlerts();
    }, [currentUser]);

    useEffect(() => {
        let handle1: NodeJS.Timeout | undefined = undefined;

        if (sites.length) {
            fetchHealthChecks();
            handle1 = setInterval(() => fetchHealthChecks(), settings.app.healthcheckTimeout);
        }

        return () => {
            if (handle1) clearInterval(handle1);
        };
    }, [sites]);

    return (
        <MainContext.Provider
            value={{
                sites,
                setSites,
                fetchSites,
                getSiteVersionById,
                chargingStations,
                setChargingStations,
                usages,
                setUsages,
                fetchUsages,
                users,
                setUsers,
                clients,
                setClients,
                healthChecks,
                setHealthChecks,
                alerts,
                setAlerts,
                fetchAlerts,
                fetchChargingStations,
                getHighestChargingStationReductionById,
                getHighestChargingStationPowerById,
                getHighestBuildingPowerUsagesBySiteId,
                getSiteUsageById,
                getChargingStationUsageById,
            }}
        >
            {children}
        </MainContext.Provider>
    );
};

export default MainContextProvider;
